export default {
    name: 'app',
    data() {
        return {
            slide: 1,
            interval: null
        }
    },
    methods: {
        setSlide: function (slide) {
            clearInterval(this.interval);
            if(slide) this.slide = slide;
            else { this.slide = this.slide <= 2? this.slide + 1 : 1 }
            this.interval = setInterval(this.setSlide, 3000)
        }
    },
    mounted() {
        //this.inteval = setInterval(this.setSlide, 3000);
    }
}