export default {
    name: 'app',
    data() {
        return {
            menu: false,
            solid: false
        }
    },
    methods: {
        updateHeader: function () {
            this.solid = window.pageYOffset > 50
        }
    },
    beforeRouteUpdate(from, to, next) {
        this.menu = false;
        next()
    },
    mounted() {
        window.onscroll = this.updateHeader
    }
}