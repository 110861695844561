import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: home
    },
    {
        path: '/nosotros',
        name: 'us',
        component: () => import('../views/us')
    },
    {
        path: '/contacto',
        name: 'contact',
        component: () => import('../views/contact')
    },
    {
        path: '/gracias',
        name: 'thanks',
        component: () => import('../views/thanks')
    },
    {
        path: '/productos',
        name: 'products',
        component: () => import('../views/products')
    },
    {
        path: '/proyectos',
        name: 'projects',
        component: () => import('../views/projects')
    },
    {
        path: '/noticias',
        name: 'news',
        component: () => import('../views/news')
    },
    {
        path: '/post/presentacion-bette',
        name: 'post.bette',
        component: () => import('../views/post/bette')
    },
    {
        path: '/post/puerto-vallarta-16-de-febrero',
        name: 'post.vallarta',
        component: () => import('../views/post/vallarta')
    },
    {
        path: '/privacidad',
        name: 'privacy',
        component: () => import('../views/privacy')
    },
    {
        path: '/banos',
        name: 'bathrooms',
        component: () => import('../views/bathrooms')
    },
    {
        path: '/institucional',
        name: 'institutional',
        component: () => import('../views/institutional')
    },
    {
        path: '/hardware',
        name: 'hardware',
        component: () => import('../views/hardware')
    },
    {
        path: '/pisos',
        name: 'floors',
        component: () => import('../views/floors')
    },
    {
        path: '/madera',
        name: 'wood',
        component: () => import('../views/wood')
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});

export default router